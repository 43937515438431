<template>
  <div class="slide-preview">
    <button @click="createPreview">preview</button>
    <div class="canvas">
      <div class="artboard" ref="artboard" data-id="" />
    </div>
    <div
      class="preview"
      v-if="!$route.params.templateId && slide"
      :style="{
        'background-image': 'url(' + slide.url.image.landscape + ')'
      }"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import html2canvas from "html2canvas";

export default {
  data: () => ({
    temp: null
  }),
  computed: {
    ...mapGetters("templates", {
      template: "getSingleTemplate"
    }),
    ...mapGetters("teams", {
      team: "getQuestionedTeam",
      parentBrand: "getParentBrand",
      generalAssets: "getGeneralAssets"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    videoPlaceholder() {
      return require("../assets/img/video-placeholder.png");
    },
    imagePlaceholder() {
      return require("../assets/img/image-placeholder.png");
    },
    brand() {
      if (this.parentBrand) {
        return this.parentBrand;
      } else {
        return this.team.brand ? this.team.brand : {};
      }
    },
    textStyles() {
      return this.brand?.textStyles;
    },
    slides() {
      return this.team?.slides ? this.team.slides : [];
    },
    slide() {
      if (this.slides.length > 0) {
        let slide = this.slides.filter(
          slide => slide.id === this.$route.params.slideId
        );

        return slide ? slide[0] : {};
      }

      return {};
    },
    orientation() {
      return this.$route.params.orientation;
    }
  },
  mounted() {
    if (
      this.$route.params.teamId &&
      this.$route.params.templateId &&
      this.$route.params.slideId
    ) {
      this.fetchTeam(this.$route.params.teamId).then(() => {
        if (this.team.parentTeam) {
          this.fetchParentTeam();
        }
        this.fetchSingleTemplate({
          teamId: this.slide.template.teamId,
          templateId: this.$route.params.templateId
        }).then(() => {
          this.fetchGeneralAssets().then(() => {
            this.setSlideElements();
            this.setFonts();
          });
        });
      });
    } else if (this.$route.params.teamId && this.$route.params.slideId) {
      this.fetchTeam(this.$route.params.teamId).then(() => {
        if (this.team.parentTeam) {
          this.fetchParentTeam();
        }
        this.setSlide();
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchSingleTemplate"]),
    ...mapActions("teams", [
      "fetchTeam",
      "fetchGeneralAssets",
      "fetchParentTeam"
    ]),
    ...mapActions("user", ["fetchUserProfile"]),
    createPreview() {
      html2canvas(this.$refs.artboard, {
        logging: true,
        letterRendering: 1,
        allowTaint: false,
        useCORS: true
      }).then(canvas => {
        const data = canvas.toDataURL();
        // Create an image from the canvas
        let img = new Image();

        img.src = data;

        // Append the image to the body
        document.body.appendChild(img);
      });
    },
    setFonts() {
      if (Object.keys(this.textStyles).length > 0) {
        let textStyles = Object.entries(this.textStyles).map(
          ([key, value]) => ({
            key,
            ...value
          })
        );
        let cssStyles = document.createElement("style");
        let newStyle = document.createElement("style");
        let fonts = {};

        if (this.brand && this.brand.fonts) {
          fonts = {
            ...this.generalAssets.brand.fonts,
            ...this.brand.fonts
          };
        } else {
          fonts = {
            ...this.generalAssets.brand.fonts
          };
        }

        textStyles.forEach(font => {
          cssStyles.appendChild(
            document.createTextNode(
              ".canvas *[data-class='" +
                font.key +
                "']{font-family:" +
                font.fontFamily +
                "; font-weight:" +
                font.fontWeight +
                "; font-size:" +
                font.fontSize +
                "px;}"
            )
          );

          newStyle.appendChild(
            document.createTextNode(
              "\
              @font-face {\
                  font-family: " +
                font.fontFamily +
                ";\
                  src: url('" +
                fonts[font.fontId].url +
                "')\
                  format('woff');\
              }\
              "
            )
          );
        });

        document.body.prepend(cssStyles);
        document.head.appendChild(newStyle);
      }
    },
    setSlideElements() {
      if (this.template.elements && this.template.elements.length > 0) {
        this.temp = this.template;
        this.temp.elements = this.template.elements.map(element => {
          if (this.slide.template.elements) {
            let slideElement = this.slide.template.elements.filter(
              e => e.id === element.id
            )[0];
            return { ...element, ...slideElement };
          }
        });
      }
      this.drawCanvas();
    },
    drawCanvas() {
      Object.assign(document.getElementsByClassName("artboard")[0].style, {
        ...this.temp.css[0],
        ...this.temp.css[this.orientation]
      });
      this.drawChildren(this.temp.elements, ".artboard");
    },
    drawChildren(elements, parent) {
      for (var key in elements) {
        var element = elements[key];
        var text = "";
        if (element.text) {
          text = "<span>" + element.text + "</span>";
        }
        var classes = "";
        for (var classKey in element.classes) {
          var className = element.classes[classKey];
          if (className != null) {
            classes += className;
          }
        }
        var image = "";
        if (element.image != undefined) {
          if (element.image.src.includes("img/image-placeholder.svg")) {
            image = "<img src='" + this.imagePlaceholder + "'></img>";
          } else {
            image = "<img src='" + element.image.src + "'>";
          }
        }

        if (element.video != undefined) {
          var poster = "";
          if (element.poster != undefined) {
            poster = "poster=" + element.poster;
          }

          if (element.video.src.includes("img/video-placeholder.svg")) {
            image = "<video poster='" + this.videoPlaceholder + "'></video>";
          } else if (
            element.video.src &&
            poster === "poster=img/video-placeholder.svg"
          ) {
            image = "<video src='" + element.video.src + "'></video>";
          } else if (
            element.video.src &&
            poster !== "poster=img/video-placeholder.svg"
          ) {
            image =
              "<video src='" + element.video.src + "' " + poster + "></video>";
          }
        }

        document
          .querySelector(parent)
          .insertAdjacentHTML(
            "beforeend",
            "<" +
              element.tag +
              " data-class='" +
              classes +
              "' data-index=" +
              key +
              " data-id=" +
              element.id +
              " data-type='" +
              element.type +
              "'" +
              poster +
              ">" +
              text +
              image +
              "</" +
              element.type +
              ">"
          );
        //document.querySelector(parent).querySelectorAll("*[data-id=" + element.id + "]")[0].css(element.css)
        Object.assign(
          document
            .querySelector(parent)
            .querySelectorAll("*[data-id='" + element.id + "']")[0].style,
          { ...element.css[0], ...element.css[this.orientation] }
        );

        this.drawChildren(
          element.children,
          parent + " *[data-id='" + element.id + "']"
        );
      }
    },
    loadBrand() {
      var cssStyles = "<style>";

      for (var key in this.brand.textStyles) {
        var font = this.brand.textStyles[key];
        cssStyles +=
          ".artboard *[data-class='" +
          key +
          "']{font-family:" +
          font.fontFamily +
          "; font-weight:" +
          font.fontWeight +
          "; font-size:" +
          font.fontSize +
          ";}";
      }

      cssStyles += "</style>";

      document
        .querySelector("body")
        .insertAdjacentHTML("afterbegin", cssStyles);
    }
  }
};
</script>

<style lang="scss">
.slide-preview .artboard * {
  margin: auto;
}
.slide-preview {
  .canvas {
    position: relative;

    .artboard {
      position: absolute !important;
      transform: scale(1) !important;
      top: 0;
      left: 0;
      overflow: hidden !important;

      svg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .preview {
    width: 1920px;
    height: 1080px;
    @apply bg-cover;
  }

  imagecontainer img {
    width: 100%;
    height: 100%;
  }

  text {
    margin: unset !important;

    span {
      white-space: pre-line;
    }
  }

  imagecontainer img,
  videocontainer video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  imagecontainer[data-class="contain"] img,
  videocontainer[data-class="contain"] video {
    object-fit: contain !important;
  }
  imagecontainer[data-class="cover"] img,
  videocontainer[data-class="cover"] video {
    object-fit: cover !important;
  }
}
</style>
